import React, {Component, useEffect, useState} from "react";
import axios from "axios";

import {DataGrid} from "@mui/x-data-grid";

export default function AllMatches () {
    const [isLoading, setLoading] = useState(true)
    const [rows, setRows] = useState([])
    const [sortModel, setSortModel] = React.useState([
        {
            field: 'date',
            sort: 'desc',
        }])

    useEffect( () => {
        getAllPlayers();
    }, [])

    const [type, setType] = useState('')
    const postType = () => {

        for(let i=0; i<rows.length;i++)
        {
            axios.post("/MatchType", {id: rows[i]["type"]}).then(res => {
                rows[i]["type"] = res.data
            })

        }
        console.log(rows)
    }

    const getAllPlayers = () => {
        axios.get("/Match").then(response => {

            var rows = []
            for (let i=0; i < response.data.length; i++){
                var resultat = response.data[i].scoreHome + " - " + response.data[i].scoreAway
                console.log(response.data[i])
                rows.push({id:response.data[i].matchID, home: response.data[i].playerHome.user.userName, away: response.data[i].playerAway.user.userName,
                    location: response.data[i].location, date: response.data[i].date, type: "Loading...", tournament: response.data[i].tournamentName,
                    result: resultat}
                )

            }
            setRows(rows)
            setLoading(false)
        });
    };


    let columns = [
        {field: 'id', headerName: 'ID', width: 70},
        {
            field: 'home',
            headerName: 'Home',
            width: 150,
            editable: true,
        },
        {
            field: 'away',
            headerName: 'Away',
            width: 150,
            editable: true,
        },
        {
            field: 'location',
            headerName: 'Location',
            width: 150,
            editable: true,
        },
        {
            field: 'date',
            headerName: 'Date',
            type: 'number',
            width: 70,
            editable: true,
        },
        {
            field: 'type',
            headerName: 'Type',
            description: 'This column has a value getter and is not sortable.',
            sortable: true,
            width: 130,
        },
        {
            field: 'tournament',
            headerName: 'Tournament',
            description: 'This column has a value getter and is not sortable.',
            sortable: true,
            width: 130,
        },
        {
            field: 'result',
            headerName: 'Result',
            description: 'This column has a value getter and is not sortable.',
            sortable: true,
            width: 130,

        },
    ];


    if(isLoading){
        return <div>Loading...</div>
    }
    return <div>
        <div style={{height: 400, width: '100%'}}>
            <DataGrid
                rows={rows}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                sortModel={sortModel}
                onSortModelChange={(model => setSortModel(model))}
            />
        </div>
    </div>


}
