import React, {useEffect, useState} from "react";
import axios from "axios";
import {DataGrid, GridToolbarDensitySelector, GridToolbarFilterButton} from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import {IconButton, TextField} from "@mui/material";
import PropTypes from "prop-types";

export default function AllPlayers ({childToParent}) {
    const [isLoading, setLoading] = useState(true)
    const[data, setData] = useState([])
    const [rows, setRows] = useState([])
    const [selectionRowData, setSRData] = useState([])
    useEffect(() => {
        getAllPlayers();
    }, [])

    function escapeRegExp(value) {
        return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    }

    const sendData = (data) => {
        childToParent(data)
    }

    function QuickSearchToolbar(props) {
        return (
            <Box
                sx={{
                    p: 0.5,
                    pb: 0,
                    justifyContent: 'space-between',
                    display: 'flex',
                    alignItems: 'flex-start',
                    flexWrap: 'wrap',
                }}
            >
                <div>
                    <GridToolbarFilterButton/>
                    <GridToolbarDensitySelector/>
                </div>
                <TextField
                    variant="standard"
                    value={props.value}
                    onChange={props.onChange}
                    placeholder="Search…"
                    //autoFocus
                    InputProps={{
                        endAdornment: (
                            <IconButton
                                title="Clear"
                                aria-label="Clear"
                                size="small"
                                style={{visibility: props.value ? 'visible' : 'hidden'}}
                                onClick={props.clearSearch}
                            >
                            </IconButton>
                        ),
                    }}
                    sx={{
                        width: {
                            xs: 1,
                            sm: 'auto',
                        },
                        m: (theme) => theme.spacing(1, 0.5, 1.5),
                        '& .MuiSvgIcon-root': {
                            mr: 0.5,
                        },
                        '& .MuiInput-underline:before': {
                            borderBottom: 1,
                            borderColor: 'divider',
                        },
                    }}
                />
            </Box>
        );
    }

    QuickSearchToolbar.propTypes = {
        clearSearch: PropTypes.func.isRequired,
        onChange: PropTypes.func.isRequired,
        value: PropTypes.string.isRequired,
    };



    const getAllPlayers = () => {
        axios.get("/Leaderboards").then(response => {
            var rows = []
            for (let i=0; i < response.data.length; i++){

                rows.push({id:response.data[i].playerID, username: response.data[i].user.userName
                    , lastName: response.data[i].user.lastName, firstName: response.data[i].user.firstName,
                    age: response.data[i].user.birthYear, rating: response.data[i].rating }
                )

            }
            setRows(rows)
            setData(rows)

            setLoading(false)

        });
    };


    let columns = [
        {field: 'id', headerName: 'ID', width: 70},
        {
            field: 'username',
            headerName: 'Username',
            width: 150,
            editable: true,
        },
        {
            field: 'firstName',
            headerName: 'First name',
            width: 150,
            editable: true,
        },
        {
            field: 'lastName',
            headerName: 'Last name',
            width: 150,
            editable: true,
        },
        {
            field: 'age',
            headerName: 'Age',
            type: 'number',
            width: 70,
            editable: true,
        },
        {
            field: 'rating',
            headerName: 'Rating',
            description: 'This column has a value getter and is not sortable.',
            sortable: true,
            width: 130,
        },
    ];


        const [searchText, setSearchText] = React.useState("");

        const requestSearch = (searchValue) => {
            setSearchText(searchValue);
            const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
            const filteredRows = rows.filter((row) => {
                return Object.keys(row).some((field) => {
                    return searchRegex.test(row[field].toString());
                });
            });

            setData(filteredRows);
        };


    if(isLoading){
        return <div>Loading...</div>
    }

    return <div>
        <div style={{height: 400, width: '100%'}}>
            <DataGrid
                components={{ Toolbar: QuickSearchToolbar }}
                rows={data}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                checkboxSelection
                disableSelectionOnClick
                onSelectionModelChange={(ids) => {
                    const selectedIDs = new Set(ids);
                    const selectionRowData = (  rows.filter((row) =>
                        selectedIDs.has(row.id)))
                    setSRData(selectionRowData)
                    sendData(selectionRowData)
                }
                }
                componentsProps={{
                    toolbar: {
                        value: searchText,
                        onChange: (event) => requestSearch(event.target.value),
                        clearSearch: () => requestSearch(''),
                    },
                }}
            />
        </div>
    </div>


}
