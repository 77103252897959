import React, {Component, useEffect, useState} from "react";

import Leaderboards from "./tables/Leaderboards";
export default function LeaderboardsMenu(){


    return (<div>
        <h1>Leaderboards</h1>
        <Leaderboards/>
    </div>)

}
