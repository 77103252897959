import React, {Component} from "react";
import axios from "axios";
import './Login.css'
import Signup from "./Signup";
import {Link, useNavigate} from "react-router-dom"

export default class Login extends Component {

    constructor() {
        super();
        this.state = {
            username: "",
            password: ""
        };
        // eslint-disable-next-line react-hooks/rules-of-hooks
        if(sessionStorage.getItem("user") !== "Log in"){
            window.location = "/"
        }
        this.updatePassword = this.updatePassword.bind(this)
        this.updateUsername = this.updateUsername.bind(this)
    }


    handlerButton = () => {
        axios.get("/User").then(response => {
            console.log(response.data)
            this.setState(
                {
                    user: response.data.userID
                }
            );

        });
    };

    postButton = () => {

        axios.post("/User", {
            "userID": 1234,
            "userName": "Olav"
        }).then(res => console.log(res.data))

    }

    updateUsername(event){
        this.setState({username:event.target.value})
    }

    updatePassword(event){
        this.setState({password:event.target.value})
    }

    postSignIn = () => {
        axios.post("/Login",{
            "userName" : this.state.username,
                "password" : this.state.password
    }).then(res => {
                if (res) {
                    sessionStorage.setItem("user", this.state.username)
                    window.location = "/"
                }else{
                    console.log(res)
                }
            }
        )
    }


    render() {
        return (

            <div className="box">
                <form>
                    <span className="text-center">login</span>
                    <div className="input-container">
                        <input type="text" onChange={this.updateUsername} required=""/>
                        <label>Username</label>
                    </div>
                    <div className="input-container">
                        <input type="password" onChange={this.updatePassword} required=""/>
                        <label>Password</label>
                    </div>

                    <button type="button" className="btn" onClick={this.postSignIn}>Log in</button>
                    <Link to="/signup">
                    <button type = "button" onClick={this.props.handler} className="btn">Sign up</button>
                    </Link>
                </form>
            </div>

        )
    }
}
