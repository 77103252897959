import * as React from 'react';

import {useEffect, useState} from "react";
import "./PlayerMatchView.css"
import {Container} from "@mui/material";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import {styled} from "@mui/material/styles";
import Paper from "@mui/material/Paper";

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function PlayerMatchView({parentToChild}) {
    let test = useState('')
    const componentDidUpdate = () =>{
        test = <h1>{parentToChild["user"]["userName"]}</h1>
    }

    const componentDidMount = () =>{
        test = <h1>Test</h1>
    }

    if(parentToChild.length === 0){
        return <h3>Loading...</h3>
    }else{

        return (
            <Box sx={{ width: '100%' }}>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item sx = {2}>
                        <hidden></hidden>
                    </Grid>
                    <Grid item sx = {8}>
                        <h3>{parentToChild["user"]["userName"]} {parentToChild["rating"]}</h3>
                        <h3>{parentToChild["user"]["lastName"]}, {parentToChild["user"]["firstName"]}</h3>
                        <h3>Age: {parentToChild["user"]["birthYear"]}</h3>
                    </Grid>
                    <Grid item sx = {2}>
                        
                    </Grid>
                </Grid>
            </Box>
        );
    }
}