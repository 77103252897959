import './App.css';
import User from "./components/User";
import Login from "./components/SignIn/Login"
import {Component} from "react";
import CreateTournament from "./components/Tournament/CreateTournament"
import Signup from "./components/SignIn/Signup";
import {Route, Link, Routes, BrowserRouter} from "react-router-dom"
import Navbar from "./components/main_comp/Navbar"
import MainMenu from "./components/menus/MainMenu";
import LeaderboardsMenu from "./components/menus/LeaderboardsMenu";
import AllTournaments from "./components/Tournament/tables/AllTournaments";
import TournamentInfo from "./components/Tournament/TournamentInfo";
import MatchInfo from "./components/menus/tables/Matches/MatchInfo";
import MatchRef from "./components/menus/tables/Matches/MatchRef";
import Footer from "./components/main_comp/Footer"
function App(){



        return (
            <div>
                    <Navbar></Navbar>
                <Routes>
                <Route path="/login" element={<Login/>}/>
                <Route path="/signup" element={<Signup/>}/>
                <Route path="/" element={<MainMenu/>} />
                    <Route path="/createtour" element={<CreateTournament/>}/>
                    <Route path="/leaderboards" element={<LeaderboardsMenu/>}/>
                    <Route path="/alltour" element={<AllTournaments/>}/>
                    <Route path="/tour" element={<TournamentInfo/>}/>
                    <Route path="/match" element = {<MatchInfo/>}/>
                    <Route path="/matchRef" element = {<MatchRef/>}/>
                </Routes>

            </div>
        )
}

export default App;
