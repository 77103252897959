import React, {Component, useEffect, useState} from "react";
import axios from "axios";

import {DataGrid, GridEventListener, GridEvents} from "@mui/x-data-grid";

export default function AllTournaments () {
    const [isLoading, setLoading] = useState(true)
    const [rows, setRows] = useState([])
    const [sortModel, setSortModel] = React.useState([
        {
            field: 'name',
            sort: 'desc',
        }])

    useEffect( () => {
        getAllTournaments();
    }, [])

    const getAllTournaments = () => {
        axios.get("/TournamentView").then(response => {

            var rows = []
            for (let i=0; i < response.data.length; i++){
                var resultat = response.data[i].scoreHome + " - " + response.data[i].scoreAway
                console.log(response.data[i])
                rows.push({id:response.data[i]["tournamentID"], name: response.data[i]["tournamentName"],
                    city: response.data[i]["city"], scoreToWin: response.data[i]["scoreToWin"]}
                )


            }
            const tempRows = rows
            setRows(tempRows)
            setLoading(false)
        });
    };


    let columns = [
        {field: 'id', headerName: 'ID', width: 150},
        {
            field: 'name',
            headerName: 'Name',
            width: 300,
            editable: true,
        },
        {
            field: 'city',
            headerName: 'City',
            width: 150,
            editable: true,
        },
        {
            field: 'scoreToWin',
            headerName: 'Score To Win',
            type: 'number',
            width: 150,
            editable: true,
        },
    ];

    const handleOnCellClick = (params) => {
        sessionStorage.setItem("tournament",params.id)
        console.log(sessionStorage.getItem("tournament"))
        window.location = "/tour"
    };


    if(isLoading){
        return <div>Loading...</div>
    }
    return <div>
        <div style={{height: 800, width: '100%'}}>
            <DataGrid
                rows={rows}
                columns={columns}
                pageSize={15}
                rowsPerPageOptions={[5]}
                sortModel={sortModel}
                onSortModelChange={(model => setSortModel(model))}
                onCellClick={handleOnCellClick}
            />
        </div>
    </div>


}
