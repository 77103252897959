import React, {Component} from "react";
import axios from "axios";
import Navbar from "./Navbar.css"
import {Link} from "react-router-dom";

export default class User extends Component {

    constructor() {
        super();

        if(sessionStorage.getItem("user") == null){
            sessionStorage.setItem("user", "Log in")
        }
    }


    handlerButton = () => {
        axios.get("/User").then(response => {
            console.log(response.data)
            this.setState(
                {
                    user: response.data.userID
                }
            );

        });
    };


    linkToLogin = () => {
        if(sessionStorage.getItem("user") === "Log in"){
            window.location = "/login"
        }else{
            window.location = "/"
        }
    }

    logOut = () => {
        sessionStorage.setItem("user", "Log in")
        window.location = "/"
    }

    linkToTournamentCreator = () => {
        window.location = "/createtour"
    }

    linkToLeaderboards = () => {
        window.location = "/leaderboards"
    }
    linkToAllTournaments = () => {
        window.location = "/alltour"
    }

    render() {
        return (

            <div>
                <ul>
                    <li><Link to="/">Home</Link></li>
                    <li onClick={this.linkToTournamentCreator}><a>Create tournament</a></li>
                    <li onClick={this.linkToLeaderboards}><a>Leaderboards</a></li>
                    <li onClick={this.linkToAllTournaments}><a>Tournaments</a></li>
                    <li onClick={this.linkToLogin}><a>{sessionStorage.getItem("user")}</a></li>
                    <li onClick={this.logOut}><a>Log out</a></li>
                </ul>
            </div>
        )
    }
}
