import React, {Component} from "react";
import axios from "axios";
import MatchTables from "./tables/MatchTables"
import {DataGrid} from "@mui/x-data-grid";
import {Container, Grid} from "@mui/material";
import Leaderboards from "./tables/Leaderboards";
import SplitScreenMainMenu from "./MainMenu/SplitScreenMainMenu";
import AllMatches from "./tables/Matches/AllMatches";
import AllPlayers from "./tables/Players/AllPlayers";
import SplitScreenCreateTournament from "../Tournament/SplitScreenCreateTournament";
export default class MainMenu extends Component {

    constructor() {
        super();

    }


    render() {
        return (

            <div>
                <h1>Welcome</h1>
                <Container justifyContent="flex-end">
                </Container>
                <SplitScreenMainMenu/>
            </div>
        )
    }
}
