import React, {Component} from "react";
import axios from "axios";

export default class User extends Component {

    constructor() {
        super();
        this.state = {
            user: "Not yet gotten"
        };
    }


    handlerButton = () => {
        axios.get("/User").then(response => {
            console.log(response.data)
            this.setState(
                {
                    user: response.data.userID
                }
            );

        });
    };

    postButton = () => {

        axios.post("/User", {
            "userID": 1234,
            "userName": "Olav"
        }).then(res => console.log(res.data))

    }

    render() {
        return (

            <div>
                <button onClick={this.postButton}>Get user</button>
                <h1>The user is {this.state.user}</h1>
                <input/>
            </div>
        )
    }
}
