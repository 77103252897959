import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Leaderboards from "../tables/Leaderboards";
import AllMatches from "../tables/Matches/AllMatches";

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function  SplitScreenMainMenu() {
    return (
        <Box sx={{ width: '100%' }}>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={6}>
                    <h2>Upcoming Matches</h2>
                    <AllMatches/>
                </Grid>
                <Grid item xs={6}>
                    <h2>Leaderboards</h2>
                    <Leaderboards/>
                </Grid>
                <Grid item xs={6}>

                </Grid>
                <Grid item xs={6}>

                </Grid>
            </Grid>
        </Box>
    );
}