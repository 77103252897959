import React, {Component, useEffect, useState} from "react";
import axios from "axios";

import {DataGrid} from "@mui/x-data-grid";

export default function Leaderboards () {
    const [players, setPlayers] = useState([])
    const [isLoading, setLoading] = useState(true)
    const [rows, setRows] = useState([])
    const [sortModel, setSortModel] = React.useState([
        {
            field: 'rating',
            sort: 'desc',
        }])

    useEffect( () => {
        getAllPlayers();
    }, [])

    const getAllPlayers = () => {
        axios.get("/Leaderboards").then(response => {
            setPlayers(response.data)
            var rows = []
            for (let i=0; i < response.data.length; i++){

                rows.push({id:response.data[i].playerID, username: response.data[i].user.userName
                    , lastName: response.data[i].user.lastName, firstName: response.data[i].user.firstName,
                    age: response.data[i].user.birthYear, rating: response.data[i].rating }
                )

            }

            setRows(rows)
            setLoading(false)
        });
    };


    let columns = [
        {field: 'id', headerName: 'ID', width: 70},
        {
            field: 'username',
            headerName: 'Username',
            width: 150,
            editable: true,
        },
        {
            field: 'firstName',
            headerName: 'First name',
            width: 150,
            editable: true,
        },
        {
            field: 'lastName',
            headerName: 'Last name',
            width: 150,
            editable: true,
        },
        {
            field: 'age',
            headerName: 'Age',
            type: 'number',
            width: 70,
            editable: true,
        },
        {
            field: 'rating',
            headerName: 'Rating',
            description: 'This column has a value getter and is not sortable.',
            sortable: true,
            width: 130,
        },
    ];


    if(isLoading){
        return <div>Loading...</div>
    }
    return <div>
        <div style={{height: 400, width: '100%'}}>
            <DataGrid
                rows={rows}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                sortModel={sortModel}
                onSortModelChange={(model => setSortModel(model))}
            />
        </div>
    </div>


}
