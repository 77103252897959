import React, {Component} from "react";
import axios from "axios";
import '../SignIn/Login.css'
import {Link, useNavigate} from "react-router-dom"
import {colors, Container, Grid, TextField} from "@mui/material";
import SplitScreenCreateTournament from "./SplitScreenCreateTournament";

export default class CreateTournament extends Component {

    constructor() {
        super();

    }




    render() {
        return (
            <div>
                <Container>
                    <h1>Tournament creator</h1>
                    <SplitScreenCreateTournament/>
                </Container>
            </div>
        )
    }
}
