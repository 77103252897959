import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import './MatchInfo.css'

import axios from "axios";
import {useEffect, useState} from "react";
import PlayerMatchView from "../Players/PlayerMatchView";

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function MatchInfo() {

    const [playerH, setPlayerH] = useState([])
    const [playerA, setPlayerA] = useState([])

    useEffect( () => {
        setPlayerH("");
        setPlayerA("")
        postTournament();
        postType();
    }, [])
    const [type, setType] = useState("")

    const postType = (id) => {

        console.log(sessionStorage.getItem("match"))
        axios.post("/MatchType", {id: id}).then(res => {
            document.getElementById("type").textContent = res.data
        })
    }

    const postTournament = () => {
        const returnValue = parseInt(sessionStorage.getItem("match"))
        console.log(sessionStorage.getItem("match"))
        axios.post("/MatchInfo", {id: returnValue}).then(res => {
            console.log(res.data)
            document.getElementById("score").textContent = res.data["scoreHome"] + " - " + res.data["scoreAway"]

            setPlayerH(res.data["playerHome"])
            setPlayerA(res.data["playerAway"])
            const tempPLayerH = res.data["playerHome"]
            setPlayerH(tempPLayerH)
            const tempPLayerA = res.data["playerAway"]
            setPlayerA(tempPLayerA)


        })

    }




    return (
        <Box sx={{ width: '100%' }}>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs = {12}>
                    <div><h1 className={"hidden"}>12</h1></div>
                    <div><h1 className={"hidden"}>12</h1></div>

                </Grid>
                <Grid item xs={3}>


                    <div>

                    </div>
                </Grid>
                <Grid item xs={2}>

                    <PlayerMatchView id="playerH" className={"block"} parentToChild={playerH}/>

                </Grid>
                <Grid item xs={2}>
                    <h2 className={"block"} id={"score"}>  vs  </h2>
                    <h2 id={"type"}></h2>
                </Grid>
                <Grid item xs={2}>
                    <PlayerMatchView id="playerA"  parentToChild={playerA}/>
                </Grid>
                <Grid item xs={3}>
                <h1>Test</h1>
                </Grid>
                <Grid item xs={4}>

                </Grid>
            </Grid>
        </Box>
    );
}