import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import "./TournamentInfo.css"

import {Button, TextField} from "@mui/material";
import AllPlayers from "../menus/tables/Players/AllPlayers";
import axios from "axios";
import {useEffect, useState} from "react";
import MatchesTournament from "../menus/tables/Matches/MatchesTournament";
import MatchInfo from "../menus/tables/Matches/MatchInfo";

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function TournamentInfo() {
    const[tournamentID, setTournamentID] = useState('')

    useEffect( () => {
        postTournament();
    }, [])

    const postTournament = () => {
        console.log(sessionStorage.getItem("tournament"))
        console.log(parseInt(sessionStorage.getItem("tournament")))
        const returnValue = parseInt(sessionStorage.getItem("tournament"))
        axios.post("/TournamentView", {id: returnValue}).then(res => {
            document.getElementById("tname").textContent = res.data["tournamentName"]
            document.getElementById("city").textContent = res.data["city"]
            document.getElementById("stw").textContent = "Score to win: " + res.data["scoreToWin"]
            })

    }

    return (
        <Box sx={{ width: '100%' }}>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} >

                <Grid item xs={4} justifyItems={"center"}   >
                    <h3 id = "tname"></h3>

                    <h3 id = "city"></h3>

                    <h3 id="stw">Score to win: </h3>

                </Grid>
                <Grid item xs={8}>
                    <MatchesTournament/>
                </Grid>
                <Grid item xs={6}>

                </Grid>
                <Grid item xs={6}>

                </Grid>
            </Grid>
        </Box>
    );
}