import React, {Component} from "react";
import axios from "axios";
import './Login.css'
import {Link} from "react-router-dom";

export default class Signup extends Component {

    constructor() {
        super();


        this.updatePassword = this.updatePassword.bind(this)
        this.updateUsername = this.updateUsername.bind(this)
        this.updateMail = this.updateMail.bind(this)
        this.updateYearBorn = this.updateYearBorn.bind(this)
        this.updateRePassword = this.updateRePassword.bind(this)
        this.updateLastName = this.updateLastName.bind(this)
        this.updateFistName = this.updateFistName.bind(this)
    }


    handlerButton = () => {
        axios.get("/User").then(response => {
            console.log(response.data)
            this.setState(
                {
                    user: response.data.userID
                }
            );

        });
    };

    postButton = () => {

        axios.post("/Signup", {
            "userID": 1234,
            "userName": "Olav"
        }).then(res => console.log(res.data))

    }

    updateUsername(event){
        this.setState({username:event.target.value})
    }

    updatePassword(event){
        this.setState({password:event.target.value})
    }

    updateFistName(event){
        this.setState({firstname:event.target.value})
    }

    updateLastName(event){
        this.setState({lastname:event.target.value})
    }

    updateMail(event){
        this.setState({mail:event.target.value})
    }

    updateRePassword(event){
        this.setState({repassword:event.target.value})
    }

    updateYearBorn(event){
        this.setState({born:event.target.value})
    }

    postSignUp = () => {

        axios.post("/Signup", {
            "userName": this.state.username,
            "firstName":this.state.firstname,
            "lastName" : this.state.lastname,
            "email" : this.state.mail,
            "birthYear":this.state.born,
            "password":this.state.password,
            "repassword":this.state.repassword
        }).then(res => {
                if (res) {
                    sessionStorage.setItem("user", this.state.username)
                    window.location = "/"
                } else {

                }

            }
        )
    }

    render() {
        return (

            <div className="box">
                <form>
                    <span className="text-center">login</span>
                    <div className="input-container">
                        <input type="text" onChange={this.updateUsername} required=""/>
                        <label>Username</label>
                    </div>
                    <div className="input-container">
                        <input type="text" onChange={this.updateFistName} required=""/>
                        <label>First name</label>
                    </div>
                    <div className="input-container">
                        <input type="text" onChange={this.updateLastName} required=""/>
                        <label>Last name</label>
                    </div>
                    <div className="input-container">
                        <input type="mail" onChange={this.updateMail} required=""/>
                        <label>Email</label>
                    </div>
                    <div className="input-container">
                        <input type="text" onChange={this.updateYearBorn} required=""/>
                        <label>Year born</label>
                    </div>
                    <div className="input-container">
                        <input type="password" onChange={this.updatePassword} required=""/>
                        <label>Password</label>
                    </div>
                    <div className="input-container">
                        <input type="password" onChange={this.updateRePassword} required=""/>
                        <label>Re-enter Password</label>
                    </div>
                    <button type="button" onClick={this.postSignUp} className="btn">Sign up</button>
                </form>
            </div>
        )
    }
}
