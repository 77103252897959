import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

import {Button, TextField} from "@mui/material";
import AllPlayers from "../menus/tables/Players/AllPlayers";
import axios from "axios";
import {useEffect, useState} from "react";

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function SplitScreenCreateTournament() {
    const[tournamentName, setTournamentName] = useState("")
    const [city, setCity] = useState("")
    const [scoreToWin, setScoreToWin] = useState("")
    const [date, setDate] = useState("")

    const postTournament = () => {

        axios.post("/Tournament", {
            "tournamentName": tournamentName,
            "city": city,
            "scoreToWin": parseInt(scoreToWin)
        }).then(res => {console.log(res.data);
        postMatches()})

    }

    const postMatches = () => {
        axios.post("/Tournament", {
            "scoreHome":0,
            "scoreAway":0,
            "date":2,
            "location":2,
            "tournamentName":2,
            "playerHome":{
                "playerID":7
            },
            "playerAway":{
                "playerID":7
            }

        }).then(res => console.log(res.data))
    }
    let [ids, setIds] = useState([])
    let [test, setTest] = useState([])
    const childToParent = (childData) => {
        setIds([])
        for(var i = 0; i < childData.length; i++){
            ids.push(childData[i].id)
        }

        const tempVar = ids

        setTest(tempVar)

    }



    const createTournament = () => {
        console.log(test)
        axios.post("/Tournament", {
            "tournamentName": tournamentName,
            "city": city,
            "scoreToWin": parseInt(scoreToWin),
            "playerIDs":test,
        }).then(res => {
            console.log(res.data);
            window.location = "/"
            })
    }

    const tournamentNameCha = (event) => {
        setTournamentName(event.target.value)
        console.log(tournamentName)
    }

    const cityCha = (event) => {
        setCity(event.target.value)
    }

    const scoreToWinCha = (event) => {
        setScoreToWin(event.target.value)
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={3}>
                    <h3>Tournament name</h3>
                    <TextField
                        required
                        id="outlined-required"
                        label="Required"
                        defaultValue="Tournament1"
                        onChange={tournamentNameCha}

                    />
                    <h3>City</h3>
                    <TextField
                        required
                        id="outlined-required"
                        label="Required"
                        defaultValue=""
                        onChange={cityCha}
                    />
                    <h3>Score to win</h3>
                    <TextField
                        required
                        id="outlined-required"
                        label="Required"
                        defaultValue=""
                        onChange={scoreToWinCha}
                    />
                </Grid>
                <Grid item xs={9}>
                    <h3>Players to add</h3>
                    <AllPlayers childToParent={childToParent}/>
                </Grid>
                <Grid item xs={8}>
                    <Button onClick={createTournament}>Create Tournament</Button>
                </Grid>
                <Grid item xs={6}>

                </Grid>
            </Grid>
        </Box>
    );
}